import * as React from 'react';
import { ShowIf } from './helpers';
import { FormTitle, FieldList, FieldItem, FieldInfo, FormFooter, TextArea, TextField, NoYesGroup, RadioGroup } from './form';
import * as styles from './form.module.scss';
const globalConfig = require('./ovic-form.json');
const formConfig = require('./foi-review-form.json');

const FOIReviewFormStep4 = (props) => {
  const {
    formData, 
    formErrors,
    onChange, 
    onSubmit,
    onPrevious,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>

      <FormTitle 
        text={formConfig.steps[3]}
      />

      <FieldList>
        <FieldItem>
          <FieldInfo 
            {...formConfig.areYouApplyingTimeframe}
          />
          <NoYesGroup 
            name="areYouApplyingTimeframe"
            value={formData.areYouApplyingTimeframe}
            error={formErrors.areYouApplyingTimeframe}
            required={true}
            onChange={onChange}
          />
        </FieldItem>
      </FieldList>

      <ShowIf cond={formData.areYouApplyingTimeframe === 'No'}>
        <FieldInfo>
          <p>We cannot accept your review unless an act or omission of the agency or Minister stopped you from requesting this review within 28 days. If you received the written decision more than 28 days ago <a href="https://ovic.vic.gov.au/about-us/contact-us/" target="_blank" rel="noopener noreferrer">contact us</a>  to discuss if you can apply for a review or not.</p><br/><br/>
        </FieldInfo>
      </ShowIf>

      <ShowIf cond={formData.areYouApplyingTimeframe === 'Yes'}>
        <FieldList>
          <FieldItem>
            <TextField 
              type="text"
              label="What date did you receive the decision letter?"
              name="decisionDate"
              value={formData.decisionDate}
              error={formErrors.decisionDate}
              required={true}
              onChange={onChange}
              characterCount={true}
              attrs={{
                maxLength: globalConfig.charLimits.short,
              }}
            />
          </FieldItem>
          <FieldItem>
            <FieldInfo 
              {...formConfig.decisionType}
            />
            <RadioGroup 
              name="decisionType"
              options={formConfig.decisionType.options}
              value={formData.decisionType}
              error={formErrors.decisionType}
              onChange={onChange}
              required={true}
            />
          </FieldItem>
        </FieldList>

        <ShowIf cond={formData.decisionType.substr(0, 4) !== 'None'}>
          <FieldList>
            <ShowIf cond={formData.decisionType.indexOf('redact') !== -1}>
              <FieldItem>
                <FieldInfo 
                  {...formConfig.personalAffairs}
                />
                <NoYesGroup 
                  name="personalAffairs"
                  value={formData.personalAffairs}
                  error={formErrors.personalAffairs}
                  required={true}
                  onChange={onChange}
                />
                <ShowIf cond={formData.personalAffairs === 'No'}>
                  <FieldInfo>
                    <p>Personal affairs information of other people can be redacted from the documents.</p>
                  </FieldInfo>
                </ShowIf>
                <ShowIf cond={formData.personalAffairs === 'Yes'}>
                  <TextArea 
                    label="What is the specific personal affairs information you want and why do you need it?"
                    name="personalAffairsDetails"
                    value={formData.personalAffairsDetails}
                    error={formErrors.personalAffairsDetails}
                    required={true}
                    onChange={onChange}
                    characterCount={true}
                    attrs={{
                      maxLength: globalConfig.charLimits.medium,
                    }}
                  />
                </ShowIf>
              </FieldItem>
              <FieldItem>
                <FieldInfo 
                  {...formConfig.editedCopy}
                />
                <NoYesGroup 
                  name="editedCopy"
                  value={formData.editedCopy}
                  error={formErrors.editedCopy}
                  onChange={onChange}
                />
                <ShowIf cond={formData.editedCopy === 'No'}>
                  <FieldInfo><p>No, I will not accept a copy of documents with irrelevant or exempt information redacted.</p></FieldInfo>
                </ShowIf>
                <ShowIf cond={formData.editedCopy === 'Yes'}>
                  <FieldInfo><p>Yes, I will accept a copy of the documents with irrelevant or exempt information redacted.</p></FieldInfo>
                </ShowIf>
              </FieldItem>
            </ShowIf>
            <FieldItem>
              <FieldInfo 
                {...formConfig.additionalInformation}
              />
              <TextArea 
                label=""
                name="additionalInformation"
                value={formData.additionalInformation}
                error={formErrors.additionalInformation}
                onChange={onChange}
                characterCount={true}
                attrs={{
                  maxLength: globalConfig.charLimits.long,
                }}
              />
            </FieldItem>
            <FieldItem>
              <FieldInfo 
                {...formConfig.agencyCopy}
              />
              <NoYesGroup 
                name="agencyCopy"
                value={formData.agencyCopy}
                error={formErrors.agencyCopy}
                onChange={onChange}
                required={true}
              />
              <ShowIf cond={formData.agencyCopy === 'No'}>
                <FieldInfo><p>No, a copy of this review application cannot be provided to the agency or Minister.</p></FieldInfo>
              </ShowIf>
              <ShowIf cond={formData.agencyCopy === 'Yes'}>
                <FieldInfo><p>Yes, a copy of this review application and any supporting information or attachments can be provided to the agency or Minister.</p></FieldInfo>
              </ShowIf>
            </FieldItem>
            <FieldItem>
              <FieldInfo 
                {...formConfig.timeExtension}
              />
              <NoYesGroup 
                name="timeExtension"
                value={formData.timeExtension}
                error={formErrors.timeExtension}
                onChange={onChange}
                required={true}
              />
              <ShowIf cond={formData.timeExtension === 'No'}>
                <FieldInfo><p>No, I do not agree to an extension. I understand that after 30 days I can apply to VCAT or otherwise wait for a decision to be made by OVIC.</p></FieldInfo>
              </ShowIf>
              <ShowIf cond={formData.timeExtension === 'Yes'}>
                <FieldInfo><p>Yes, I agree to the initial 30 day period being extended by an additional 90 days.</p></FieldInfo>
              </ShowIf>
            </FieldItem>
          </FieldList>
          <FormFooter 
            next="Next: Contact details"
            onPrevious={onPrevious}
          />
        </ShowIf>
      </ShowIf>

    </form>
  )
}

export default FOIReviewFormStep4;
